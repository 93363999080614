import React from 'react'
import { Link } from 'react-router-dom'

function CustomizeStore() {

    return (
        <div>
            <Link to={'/'}>Back</Link>
        </div>
    )
}

export default CustomizeStore