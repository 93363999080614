import { Crisp } from "crisp-sdk-web";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

const CrispIntegration = () => {
    const userData = useSelector((state) => state.user.value);
    const storeData = useSelector((state) => state.store.value);
    useEffect(() => {
        Crisp.configure("9ebda65d-080b-49ce-8a42-07d0c7f2c231"); // Replace with your Crisp Website ID

        console.log("userData::", userData);

        if (userData?.name) {
            // Set user's name
            Crisp.user.setNickname(userData.name);

            // Set user's email
            if (userData.email) {
                Crisp.user.setEmail(userData.email);
            }

            // Set user's phone with proper formatting
            if (userData.mobile) {
                Crisp.user.setPhone(`91${userData.mobile}`);
            }

            if (storeData?.logo) {
                Crisp.user.setAvatar(storeData?.logo);
            }

            // Set company information
            if (storeData) {
                Crisp.user.setCompany(storeData.name ?? "N/A", {
                    url: `https://${storeData?.storeType == "SHOPIFY" ? storeData?.shopifyStoreUrl : userData?.expired ? storeData?.domain : storeData?.customDomain ?? storeData?.domain}`,
                    description: storeData?.shortDescription ?? "",
                    geolocation: {
                        city: storeData?.city ?? "",
                        country: storeData?.country ?? "",
                    },
                });
            }

            // Set the color theme
            // Crisp.setColorTheme("teal");
        }
    }, [userData, storeData]);

    return null; // No UI needed for this component
};

export default CrispIntegration;
