// ApiData
const BASE_URL = (window?.location?.hostname === 'app.printfuse.in') ? "https://app.printfuse.in/" : `http://${window?.location?.host}/`
const SERVER_URL = (window?.location?.hostname === 'app.printfuse.in') ? "https://api.printfuse.in/" : "http://192.168.29.144:4000/"
// const BASE_URL = `"https://app.printfuse.in/"`;
// const SERVER_URL = "https://api.printfuse.in/";
const API_SERVER_URL = `${SERVER_URL}`;
const FRONTEND_API_URL = `${API_SERVER_URL}seller/`;

// frontend API routes
const frontEnd_API = {
    // auth module
    masterSignIn: `${FRONTEND_API_URL}auth/master/signin`,
    signin: `${FRONTEND_API_URL}auth/signin`,
    signup: `${FRONTEND_API_URL}auth/signup`,
    updatepassword: `${FRONTEND_API_URL}auth/updatepassword`,
    status: `${FRONTEND_API_URL}auth/status`,
    signout: `${FRONTEND_API_URL}auth/signout`,
    postUpdateProfile: `${FRONTEND_API_URL}auth/profile`,
    postUpdatePassword: `${FRONTEND_API_URL}auth/changepassword`,
    getProfile: `${FRONTEND_API_URL}auth/profile`,
    sendotp: `${FRONTEND_API_URL}auth/sendotp`,
    resendotp: `${FRONTEND_API_URL}auth/resendotp`,
    verifyotp: `${FRONTEND_API_URL}auth/verifyotp`,
    getIdentity: `${FRONTEND_API_URL}home/identity`,
    home: `${FRONTEND_API_URL}home`,
    getSocial: `${FRONTEND_API_URL}home/social`,
    getcategory: `${FRONTEND_API_URL}home/category`,
    gettag: `${FRONTEND_API_URL}home/tag`,
    getGlobal: `${FRONTEND_API_URL}home/global`,
    users: `${FRONTEND_API_URL}home/user`,
    orderstatus: `${FRONTEND_API_URL}home/orderstatus`,
    returnOrderStatus: `${FRONTEND_API_URL}home/returnorderstatus`,
    sendVerifictionMail: `${FRONTEND_API_URL}home/sendverificationmail`,
    verifyEmail: `${FRONTEND_API_URL}home/verifyemail/`,
    postStore: `${FRONTEND_API_URL}store`,
    updateStore: `${FRONTEND_API_URL}store`,
    getStore: `${FRONTEND_API_URL}store`,
    getSingleStore: `${FRONTEND_API_URL}store`,
    snapStore: `${FRONTEND_API_URL}storesocial`,
    coupon: `${FRONTEND_API_URL}coupon`,
    page: `${FRONTEND_API_URL}page`,
    storeSwitch: `${FRONTEND_API_URL}store/switch`,
    themeChange: `${FRONTEND_API_URL}store/theme`,
    checkDomain: `${FRONTEND_API_URL}store/checkdomain`,
    storeReturnDetails: `${FRONTEND_API_URL}store/return`,
    storeCustomDomain: `${FRONTEND_API_URL}store/customdomain`,
    postAddress: `${FRONTEND_API_URL}address`,
    getAddress: `${FRONTEND_API_URL}address`,
    deleteAddress: `${FRONTEND_API_URL}address`,
    primaryAddress: `${FRONTEND_API_URL}address/primary`,
    filterProductList: `${FRONTEND_API_URL}product/list`,
    singleProduct: `${FRONTEND_API_URL}product/detail`,
    singleOrderProduct: `${FRONTEND_API_URL}order/product`,
    filters: `${FRONTEND_API_URL}home/filter`,
    pricing: `${FRONTEND_API_URL}home/pricing`,
    dashboard: `${FRONTEND_API_URL}home/dashboard`,
    walletBalance: `${FRONTEND_API_URL}home/balance`,
    document: `${FRONTEND_API_URL}document`,
    storeProduct: `${FRONTEND_API_URL}storeproduct`,
    orders: `${FRONTEND_API_URL}order`,
    rtoOrder: `${FRONTEND_API_URL}rtoorder`,
    rtsOrder: `${FRONTEND_API_URL}rtsorder`,
    returnorder: `${FRONTEND_API_URL}returnorder`,
    sellerWallet: `${FRONTEND_API_URL}sellerwallet`,
    sellerwithdraw: `${FRONTEND_API_URL}sellerwithdraw`,
    sellersubscription: `${FRONTEND_API_URL}sellersubscription`,
    banner: `${FRONTEND_API_URL}banner`,
    bank: `${FRONTEND_API_URL}bank`,
    notification: `${FRONTEND_API_URL}notification`,
    getCartList: `${FRONTEND_API_URL}order/cart`,
    getTaxList: `${FRONTEND_API_URL}order/counttax`,
    listsection: `${FRONTEND_API_URL}home/listsection`,
    brandingList: `${FRONTEND_API_URL}home/branding`,
    liveCounting: `${FRONTEND_API_URL}home/livecounting`,
    currentPlanDetail: `${FRONTEND_API_URL}home/currentplandetail`,
    snapFilterProductList: `${API_SERVER_URL}snapstore/product/list`,
    snapFilterProductList: `${API_SERVER_URL}snapstore/product/list`,
}

// Site Confige

const siteConfig = {
    name: 'Printfuse',
    logo: require('../Assets/Images/try-logo.png'),
    logoText: require('../Assets/Images/oglogo.png'),
    tagLine: 'Join The Next Revolution',
    address: {
        addressLine1: '64, Akshay Industrial Estate,',
        addressLine2: 'Near New Cloth Market',
        city: 'Ahmedabad',
        pinCode: '385620',
    },
    websiteLink: 'https://printfuse.in/',
    email: 'printfuse@help.com',
    GSTIN: '24HDE7487RE5RT4',
    bankName: 'State Bank of India',
    branchName: 'RAF CAMP',
    bankAccountNumber: '2000000004512',
    IFSCCode: 'SBIN0000488',
    rules: ["Subject to Ahmedabad Jurisdiction.", "Our responsibility ceases as soon as the goods leave our premises.", "Goods once sold will not be taken back.", "Delivery ex-premises."]
};

const constConfig = {
    user: 'USER',
    seller: 'SELLER'
}

// localStorageNames
const storage = {
    user: 'seller',
    loader: 'loader',
    store: 'activeStore',
    domain: 'domain',
    canvas: 'canvas',
    cart: 'cart',
}

const token = JSON.parse(localStorage?.getItem(storage.user))
    ? `Bearer ${JSON.parse(localStorage?.getItem(storage.user))?.token}`
    : 'essentials';

const storeCode = JSON.parse(localStorage?.getItem(storage.user))
    ? `${JSON.parse(localStorage?.getItem(storage.user))?.store?.storeCode}`
    : '';

const header = {
    headers: {
        'token': token,
        'storecode': storeCode,
        'Content-Type': 'application/json'
    }
}

const headerImage = {
    headers: {
        'token': token,
        'storecode': storeCode,
        'Content-Type': 'multipart/form-data'
    }
}

export {
    BASE_URL,
    SERVER_URL,
    API_SERVER_URL,
    FRONTEND_API_URL,
    frontEnd_API,
    token,
    storeCode,
    header,
    headerImage,
    storage,
    constConfig,
    siteConfig
};