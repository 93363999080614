import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Alert, Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Image, Row } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { frontEnd_API, header, storage, token } from '../../Config/Config';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import Loader from '../../Components/Loader';
import { GiNotebook } from 'react-icons/gi';
import moment from 'moment';
import CommonPagination from '../../Components/CommonPagination';
import * as XLSX from 'xlsx';
import { IoDownload } from 'react-icons/io5';

function SellerUserList() {

    const navigate = useNavigate()
    
    const activeStore = useSelector((state) => state.store.value)
    const userDetail = useSelector((state) => state.user.value)
    
    const [loader, setLoader] = useState(false);
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState({});
    const [totalList, setTotalList] = useState(10);

    const getUserList = async () => {
        try {
            const { data } = await axios.put(frontEnd_API?.users, { ...filters }, header)
            setUserList(data?.data);
            setTotalList(data?.totalCount)
        }
        catch (e) {
            console.log("Err::", e);
        }
    }
    // -----


    // function for export user data into excel sheet -----
    const exportToExcel = async () => {
        
        if (userDetail?.expired) {
            Swal.fire({
                title: `Failed`,
                text: `Upgrade to Pro plan to export customers information in excel.`,
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,
                confirmButtonColor: '#12715B',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Subscribe',
                cancelButtonText: 'No, Thanks',
            }).then(async (result) => {
                if (result.isConfirmed)
                    navigate("/subscription")
            });
            return
        }

        try {
                        
            const exportHeading = ['Sr No', 'Register Date', 'Customer Name', 'Email', 'Mobile', 'City', 'State', 'No Of Orders']

            const { data } = await axios.put(frontEnd_API?.users, { ...filters, isExport:true }, header)
            
            const newData = await data?.data?.map((e, index) => ({
                key: index + 1,
                updatedAt: e?.updatedAt,
                name: e?.name,
                email: e?.email,
                mobile: e?.mobile,
                city: e?.address?.city ?? '-',
                state: e?.address?.state ?? '-',
                totalOrders: e?.totalOrders ?? '-'
            }))?.map(e => Object?.values(e))

            const workBook = XLSX.utils.book_new()

            const workSheet = XLSX.utils.json_to_sheet([...newData])

            XLSX.utils.book_append_sheet(workBook, workSheet, ['ORDER'])
            XLSX.utils.sheet_add_aoa(workSheet, [exportHeading], { origin: "A1" })

            XLSX.writeFile(workBook, `CUSTOMER-EXCEL-REPORT-${moment().format('DD-MM-YYYY-HH-mm-ss')}.xlsx`)
        } catch (error) {
            console.log("error::", error);
        }
    }
    // -----

    useEffect(() => {
        getUserList();
    }, [])

    return (
        <div className='seller-return-order py-4 py-sm-5' style={{ minHeight: "100vh" }}>
            <Container className='seller-return-order-main-container'>
                <div className='d-grid gap-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h1 className='m-0 fs-25 fw-semibold'> Customers List</h1>
                        <Button variant='success' onClick={exportToExcel} >Export to Excel</Button>
                    </div>
                    <div className='seller-return-order-container p-2 p-sm-3'>
                        <div className='pb-2 d-grid gap-3 custom-btn-scrollbar'>

                            <div className='peta-height-fix'>
                                <div className='custom-order-list-table-overflow'>
                                    <div className='custom-order-list-table-overflow-div'>
                                        <table className='custom-order-list-table'>
                                            <thead className=''>
                                                <tr className='fs-12 fs-sm-10 fw-medium p-color'>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Mobile</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Orders</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    loader ?
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className='w-100 aspect-4-1 aspect-md-2-1'>
                                                                    <Loader />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        : (userList?.length <= 0)
                                                            ? <tr>
                                                                <td colSpan={7} className='py-5'>
                                                                    <div className='custom-transaction-tab d-grid justify-content-center text-center gap-2'>
                                                                        <div>
                                                                            <GiNotebook />
                                                                        </div>
                                                                        <h5 className='fw-semibold'>No users yet</h5>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            :
                                                            <>
                                                                {
                                                                    userList.map((item, index) => {
                                                                        return (
                                                                            <tr
                                                                                key={index}
                                                                                className='custom-order-list-bar-item fs-12 fs-sm-10'
                                                                            // style={(item?.returnOrderId != 0) ? { backgroundColor: "rgb(255, 203, 203)" } : {}}
                                                                            >
                                                                                <td style={{ width: "fit-content" }} >{item?.updatedAt}</td>
                                                                                <td>{item?.name}</td>
                                                                                <td>{item?.email}</td>
                                                                                <td>{item?.mobile}</td>
                                                                                <td>{item?.address?.city?? '-'}</td>
                                                                                <td>{item?.address?.state?? '-'}</td>
                                                                                <td>{item?.totalOrders?? '-'}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <CommonPagination
                                totalCount={totalList}
                                changePage={(data) => {
                                    setFilters((prevData) => ({
                                        ...prevData,
                                        limit: data?.limit,
                                        page: data?.page
                                    }))
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default SellerUserList