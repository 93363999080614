import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './Assets/sass/style.scss';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routes from './Routes/routes';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "@fontsource/poppins";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specify weight
import "@fontsource/inter/400-italic.css";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'flatpickr/dist/flatpickr.min.css';
import SellerSideBar from './View/SellerDashboard/SellerSideBar';
import axios from 'axios';
import { removeLoggedUser, updateUser } from './Store/Slices/UserSlice';
import { BASE_URL, frontEnd_API, header, storeCode, token } from './Config/Config';
import Loader from './Components/Loader';
import { storeLoader } from './Store/Slices/homeLoader';
import NotificationBell from './Components/NotificationBell';
import InterComChatBot from './Components/InterComChatBot';
import BlockPopUp from './BlockPopUp';
import StatusCheckComponent from './StatusCheckComponent';
import moment from 'moment';
import 'animate.css';
import SellerSubscriptionPlanDetail from './Components/SellerSubscriptionPlanDetail';
import { removeStore } from './Store/Slices/StoreSlice';
import CrispIntegration from './Components/CrispIntegration';

function App() {
    const user = useSelector((state) => state.user.value);
    const localLoader = useSelector((state) => state.loader.value);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (user) {
            if (user?.storeId !== 0) {
                dispatch(storeLoader(1));
                if (localLoader !== 1) {
                    setLoader(true);
                    setTimeout(() => {
                        setLoader(false);
                    }, 3000);
                } else {
                    setLoader(false);
                }
            } else {
                setLoader(false);
            }
        } else {
            setLoader(false);
        }
    }, []);

    // Normalize paths to prevent double slashes
    useEffect(() => {
        const normalizedPath = location.pathname.replace(/\/+/g, '/');
        if (location.pathname !== normalizedPath) {
            navigate(normalizedPath, { replace: true });
        }
    }, [location.pathname, navigate]);

    useEffect(() => {
        const { pathname } = location;

        if (!user) {

            const isRestrictedPath =
                pathname.startsWith('/') && !pathname.startsWith('/view') ||
                pathname === '/create-store';

            const isSignUpOrSignInPage =
                pathname.endsWith('/signup') ||
                pathname.endsWith('/forgot-password') ||
                pathname.endsWith('/signin') ||
                pathname.startsWith('/master') ||
                pathname.startsWith('/verifyemail');

            if (isRestrictedPath && !isSignUpOrSignInPage) {
                navigate('/signin');
            }
        } else {
            const { pathname } = location;
            if (localLoader === 1 && (pathname.endsWith('/signup') || pathname.endsWith('/forgot-password') || pathname.endsWith('/signin'))) {
                navigate('/');
            }
        }
    }, [user, location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const showHeaderFooter = (
        user?.storeId !== 0 &&
        !['/signin', '/signup', '/forgot-password', '/view', '/master', '/verifyemail', '/customize-store'].some(path => location.pathname.startsWith(path)) &&
        !['/canvas', '/app/create-store'].some(path => location.pathname.endsWith(path))
    );
    const [childStateValue, setChildStateValue] = useState();

    const handleChildStateChange = (value) => {
        setChildStateValue(value);
    };

    const getWalletBalance = async () => {
        if (user?.id > 0 && !location.pathname.startsWith('/verifyemail')) {
            try {
                const { data } = await axios.get(frontEnd_API?.walletBalance, header);
                dispatch(updateUser({ walletBalance: data?.data?.walletBalance }));
            } catch (e) {
                console.log("Err::", e);
            }
        }
    };

    // function for get status of seller -----
    const getSellerStatus = async () => {
        if (user?.id > 0 && !location.pathname.startsWith('/verifyemail')) {
            const { data } = await axios.put(`${frontEnd_API?.status}`, { verifyStatus: 1 }, header);
            if (user?.status !== data?.data?.status) {
                console.log("130ddd")
                dispatch(updateUser({ status: data?.data?.status }));
                window.location = BASE_URL;
            }
        }
    };
    // -----

    useEffect(() => {
        if (showHeaderFooter && user?.id > 0) getSellerStatus();
    }, [location.pathname]);

    useEffect(() => {
        getWalletBalance();
    }, []);

    useEffect(() => {
        const { pathname } = location;
        if (user && user?.storeId === 0) {
            if (pathname.startsWith('/master') || pathname.startsWith('/verifyemail')) {
                dispatch(removeStore())
                dispatch(removeLoggedUser())
            }
            else {
                navigate('/app/create-store')
            }
        }
    }, [navigate, user]);

    return (
        <>
            {
                loader ? (
                    <div className='main-loader'>
                        <Loader />
                    </div>
                ) : (
                    <div className={`${user?.status <= 0 ? 'position-relative block-panel' : ''}`}>
                        <div className={(showHeaderFooter) && `d-lg-flex custom-dashboard-panel ${childStateValue ? 'is-collapsed' : ''}`}>
                            {
                                (showHeaderFooter) &&
                                <div className='dashboard-panel-sidebar'>
                                    <SellerSideBar onStateChange={handleChildStateChange} />
                                </div>
                            }
                            <div className={(showHeaderFooter) && 'dashboard-panel-content'}>
                                {
                                    (user?.id > 0 && !location?.pathname?.endsWith('/canvas') && !['/verifyemail', '/master/signin', '/signin', '/signup'].some(path => location.pathname.startsWith(path))) &&
                                    // (user?.id > 0 && !location?.pathname?.endsWith('/canvas') && !location.pathname.startsWith('/verifyemail')) &&
                                    <SellerSubscriptionPlanDetail />
                                }
                                <div>
                                    <Routes>
                                        {routes.map((item, index) => <Route key={index} path={item.path} element={item.element} />)}
                                    </Routes>
                                </div>
                            </div>
                            {
                                (user?.id > 0 && !location?.pathname?.startsWith('/view') && !location?.pathname?.endsWith('/canvas')) &&
                                <NotificationBell />
                            }
                        </div>
                        {(user?.status <= 0) && <BlockPopUp />}
                    </div>
                )
            }
            <CrispIntegration />
        </>
    );
}

export default App;