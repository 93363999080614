import React, { useEffect, useState } from 'react'
import { BASE_URL, frontEnd_API, header, siteConfig } from '../../Config/Config';
import axios from 'axios';
import { Container, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { RiShareBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from 'react-redux';
import { updateStore } from '../../Store/Slices/StoreSlice';
import { updateUser } from '../../Store/Slices/UserSlice';
import Loader from '../../Components/Loader';
import { FaShopify } from 'react-icons/fa6';

function SellerStoreListing() {
    const [stores, setStores] = useState([]);
    const activeStore = useSelector((state) => state.store.value);
    const userData = useSelector((state) => state.user.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        axios.get(frontEnd_API.getStore, header)
            .then((res) => {
                setStores(res?.data?.data);
                console.log(res?.data?.data);
                setLoader(false);
            })
            .catch((err) => {
                console.log("err::", err);
                setLoader(false);
            })
    }, [])

    const customHover = (item) => {
        const data = <OverlayTrigger
            placement={item.direction}
            delay={{ show: 100, hide: 100 }}
            overlay={
                <Tooltip id="button-tooltip">
                    {item.content}
                </Tooltip>
            }
        >
            <span>{item.title}</span>
        </OverlayTrigger>
        return data;
    }

    const handleSwitch = async (item) => {
        if (activeStore.id === item.id) {
            navigate(`/store-setting`)
            return
        }

        if (window.confirm(`Are you sure to switch from "${activeStore.name}" to "${item.name}" ?`)) {
            setLoader(true);
            try {
                const { data } = await axios.put(frontEnd_API.storeSwitch, { storeId:item?.id }, header)
                dispatch(updateStore(data?.data))
                dispatch(updateUser({
                    storeCode: data?.data?.storeCode,
                    store: data?.data,
                    storeId: data?.data?.id
                }))
                setLoader(false);
                window.location.href = `${BASE_URL}/store-setting`;
            }
            catch (e) {
                console.log("err::", e);
                setLoader(false);
            }
        }
    }

    document.title = `Store List | ${siteConfig?.name}`;
    return (
        <div className='seller-store-listing px-2 px-sm-3 pb-5'>
            <Container>
                <div className='d-grid justify-content-sm-between d-sm-flex py-4 py-sm-5 gap-3 w-100'>
                    <h2 className='fw-semibold m-0'>My Stores</h2>
                    <Link to={'/create-store'} className='add-store-btn fw-semibold'>Add new store</Link>
                </div>
            </Container>
            <div className='custom-back-box'>
                {
                    (loader) ?
                        <div className='w-100 aspect-5-1 aspect-md-2-1'>
                            <Loader />
                        </div>
                        : (stores.length > 0)
                            ? stores?.map((item, index) => {
                                return (
                                    <Container key={index} className='seller-box no-border-rad p-3 px-3 p-lg-4 px-lg-5'>
                                        <div className='row flex-between-align align-items-start align-items-md-center'>
                                            <div className='col-9 d-grid'>
                                                <div className='row gy-2 align-items-center'>
                                                    <div className='col-12 col-md-2 custom-img-container flex-between-align justify-content-start gap-3 gap-md-1 gap-lg-1 gap-xl-4'>
                                                        <span className={`${(activeStore.id !== item.id) && 'd-none'} d-md-block`} style={{ backgroundColor: (activeStore.id !== item.id) && 'transparent' }}></span>
                                                        <div className={`custom-img-holder ${(activeStore.id === item.id) && 'custom-img-holder-active'}`}>
                                                            <img src={(item?.logo) ? item.logo : require('../../Assets/Images/try-logo.png')} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-md-5 d-grid align-content-center gap-1'>
                                                        <h5 className='m-0 fw-semibold text-capitalize'>{item.name}</h5>
                                                        <Link className='text-decoration-none flex-center-align width-fit justify-content-start gap-1 fs-12' to={`${`https://${item?.shopifyStoreUrl ? item?.shopifyStoreUrl : !userData?.expired ? item?.customDomain ?? item?.domain : item?.domain}`}`} target='_blanck'>
                                                            {
                                                                item?.storeType == "SHOPIFY" &&
                                                                <FaShopify className='color-temp fs-16' />
                                                            }
                                                            {item?.shopifyStoreUrl ? item?.shopifyStoreUrl : !userData?.expired ? item?.customDomain ?? item?.domain : item?.domain}
                                                            <RiShareBoxLine />
                                                        </Link>

                                                        {/* {customHover({ title: <div>Automatic (24h)</div>, content: 'Order approval and processing', direction: 'bottom' })} */}
                                                    </div>
                                                    <div className='col-12 col-md-4 d-flex gap-2 gap-sm-3 gap-lg-4 fs-14 p-color'>
                                                        <span>Order {item?.totalOrders ?? 0}</span>
                                                        <span>Products {item?.totalProducts ?? 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-3 d-flex gap-3 btn-css justify-content-end'>
                                                {
                                                    customHover({
                                                        title:
                                                            <div className='fs-20 custom-link-btn'
                                                                onClick={() => handleSwitch(item)}>
                                                                <i className="bi bi-gear-fill"></i>
                                                            </div>,
                                                        content: 'Settings',
                                                        direction: 'top'
                                                    })}
                                                {/*
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        {customHover({ title: <Link className='fs-20 custom-link-btn'><i className="bi bi-three-dots"></i></Link>, content: 'More Details', direction: 'top' })}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                */}
                                            </div>
                                        </div>
                                    </Container>
                                )
                            })
                            : <h2 className='text-center d-grid d-sm-flex flex-center-align gap-3 py-4'><i className="bi bi-shop"></i>No Data Found</h2>
                }
            </div>
        </div>
    )
}

export default SellerStoreListing